.groupListButton {
    width: 100%;
    color: #c2c2c2;
    padding: 10px 10px;
    border: none;
    transition: all ease-in-out 0.2s;
    background-color: white;
    text-align: left;
    border-radius: 10px;
    font-size: 14px;
}

.groupListButton:focus {
    outline: none;
}

.groupListButton-selected {
    width: 100%;
    color: #fc7242;
    background-color: #fcf9ed;
    padding: 10px 10px;
    border: none;
    transition: all ease-in-out 0.2s;
    text-align: left;
    border-radius: 10px;
    font-size: 14px;
}

.groupListButton-selected:focus {
    outline: none;
}