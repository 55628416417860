table {
    width: 100%;
    border-collapse: collapse;
}

th {
    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #f0f0f0;
    font-size: 14px;
    font-weight: 700;
    color: #757575;
}

#tdComponent-err {
    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #757575;
    font-size: 14px;
    border-bottom: 2px solid #f0f0f0;
}

tr {
    transition: background-color 0.2s ease-in-out;
}

tr:hover {
    background-color: #f9f9f9;
}

.detailLink {
    color: #757575;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
}

.detailLink:hover {
    color: black;
}