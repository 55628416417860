.accordionHeaderBtn {
    width: 100%;
    padding: 0px;
    background-color: white;
    border: none;
    text-align: left;
    color: #757575;
    transition: all ease-in-out 0.2s;
}

.accordionHeaderBtn:focus {
    outline: none;
}