.dropDown-container {
    -webkit-box-shadow: 10px 0px 10px 0px rgba(117,117,117,0.66);
    -moz-box-shadow: 10px 0px 10px 0px rgba(117,117,117,0.66);
    box-shadow: 10px 0px 10px 0px rgba(117,117,117,0.66);
}

.dropDown-menu {
    background: none;
    border: none;
    font-size: 14px;
    color: #757575;
}

.dropDown-menu:focus {
    outline: none;
}

.dropDown-menu:hover {
    text-decoration: underline;
}