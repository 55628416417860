.multi-select-box_custom {
    width: 100%;
    height: 100%;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    padding: 10px;
    transition: all ease-in-out 0.2s;
    font-size: 13px;
}

.multi-select-box_custom:focus {
    border-color: #FC7242;
}

.rmsc {
    --rmsc-main: #fc7242 !important;
    --rmsc-hover: #f1f3f5;
    --rmsc-selected: #e2e6ea;
    --rmsc-border: #ebebeb !important;
    --rmsc-gray: #aaa;
    --rmsc-bg: #fff;
    --rmsc-p: 10px; /* Spacing */
    --rmsc-radius: 5px !important; /* Radius */
    --rmsc-h: 40px !important; /* Height */
    font-size: 13px;
}
