#group-top-nav-item {
    margin: auto 10px;
    padding: 5px 15px 5px 15px;
    border-radius: 5px 5px 0px 0px;
    background-color: #EBEBEB;
    color: #B0B0B0;
    transition: all ease-in-out 0.2s;
}

#group-top-nav-item:hover {
    color: #505050;
}

#role-side-nav-item {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    outline: none;
    background-color: white;
    border: 1px solid #B8B8B8;
    color: #B8B8B8;
    border-radius: 5px;
    transition: all ease-in-out 0.2s;
}

#role-box-title {
    color: #c2c2c2;
    font-size: medium;
}

#role-assign-btn {
    margin: 5px;
    outline: none;
    border: none;
    background: none;
    color: #B0B0B0;
    transition: all ease-in-out 0.2s;
}

.groupListButton {
    width: 100%;
    color: #c2c2c2;
    padding: 10px 10px;
    border: none;
    transition: all ease-in-out 0.2s;
    background-color: white;
    text-align: left;
    border-radius: 10px;
}

.groupListButton:focus {
    outline: none;
}

.groupListButton-selected {
    width: 100%;
    color: #fc7242;
    background-color: #fcf9ed;
    padding: 10px 10px;
    border: none;
    transition: all ease-in-out 0.2s;
    text-align: left;
    border-radius: 10px;
}

.groupListButton-selected:focus {
    outline: none;
}

.roleButton {
    width: 100%;
    background-color: white;
    border: 1px solid #757575;
    border-radius: 10px;
    color: #757575;
    outline: none;
    transition: all ease-in-out 0.2s;
}

.roleButton:focus {
    outline: none;
}

