p {
    font-size: 25px;
    font-weight: 700;
}

#login-btn {
    margin-top: 50px;
    background-color: #FC7242;
    border: none;
    color: white;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
    border-radius: 15px;
    box-shadow: 0px 10px 13px -7px #666666;
    transition: background-color 0.2s ease-in-out;
    outline: none;
}

#login-btn:hover {
    background-color: #FFFFFF;
    color : #FC7242;
}