#language-btn {
    display: inline-block;
    background: none;
    outline: none;
    border: none;
    padding: 0px;
    margin-right: 15px;
    font-weight: 400;
    font-size: 13px;
    color: #d3d3d3;
}

.mainContainer {
    width: 100%;
    height: 900px;
    background-color: white;
    border-radius: 10px;
    padding: 50px;
}

.headingTitle {
    width: 100%;
    height: 50px;
    background-color: white;
    display: flex
}

.contentContainer {
    width: 100%;
    height: 1000px;
    padding: 10px;
}

.contentInner {
    width: 100%;
    padding: 15px;
    background-color: white;
    border-radius: 5px;
}

#small-title {
    color: #757575;
    font-size: 14px;
}

#small-small-title {
    color: #696969;
    font-size: 14px;
    font-weight: bold;
}

#small-input-text {
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #c2c2c2;
    color: #696969;
    transition: all ease-in-out 0.2s;
}

#small-input-text:focus {
    border-color: #FC7242;
}

#small-textarea {
    width: 100%;
    padding: 5px 10px;
    height: 100px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #c2c2c2;
    color: #696969;
    resize: none;
    transition: all ease-in-out 0.2s;
}

#small-textarea:focus {
    border-color: #FC7242;
}

select {
    outline: none;
    -webkit-appearance:none; /* for chrome */
    -moz-appearance:none; /*for firefox*/
    appearance:none;
}

select::-ms-expand{
    display:none;/*for IE10,11*/
}

select {
    background:url('arrow-down.png') no-repeat 96% 50%/24px auto;
    padding-right: 3%;
}

#search-field-select-box {
    height: 100%;
    width: 100%;
    background-color: white;
    border: 2px solid #ebebeb;
    border-radius: 10px;
    outline: none;
    padding-left: 15px;
    transition: all ease-in-out 0.2s;
    color: #757575;
    font-size: 14px;
}

#search-field-select-box:focus {
    border-color: #FC7242;
}

input {
    outline: none;
}

#search-like-input {
    width: 100%;
    height: 100%;
    border: 2px solid #ebebeb;
    border-radius: 10px;
    padding: 15px;
    transition: all ease-in-out 0.2s;
    font-size: 14px;
    color: #757575;
}

#search-like-input:focus {
    border-color: #FC7242;
}

#box-btn {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    border-radius: 10px;
    background-color: #FC7242;
}

#pagination-btn {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    border-radius: 10px;
    background-color: white;
}

#data-view-input {
    width: 100%;
    height: 100%;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    padding: 10px;
    transition: all ease-in-out 0.2s;
    font-size: 13px;
}

#data-view-input:focus {
    border-color: #FC7242;
}

#data-view-input-disabled {
    width: 100%;
    height: 100%;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    padding: 10px;
    transition: all ease-in-out 0.2s;
    font-size: 13px;
}

#data-view-input-disabled:focus {
    border-color: #FC7242;
}

#save-btn-disabled {
    outline: none;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid #c2c2c2;
    color: #c2c2c2;
    background-color: white;
    transition: all ease-in-out 0.2s;
    font-size: 13px;
}

#save-btn {
    outline: none;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid #fc7242;
    color: #fc7242;
    background-color: white;
    transition: all ease-in-out 0.2s;
    font-size: 13px;
}

#save-btn:hover {
    border: 1px solid #FC7242;
    color: white;
    background-color: #FC7242;
}

#img-edit-btn {
    outline: none;
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid #c2c2c2;
    color: white;
    background-color: #c2c2c2;
    transition: all ease-in-out 0.2s;
    font-size: 14px;
}

#img-edit-btn:hover {
    border: 1px solid #c2c2c2;
    color: #c2c2c2;
    background-color: white;
}

#edit-btn {
    outline: none;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid #c2c2c2;
    color: white;
    background-color: #c2c2c2;
    transition: all ease-in-out 0.2s;
    font-size: 14px;
}

#edit-btn:hover {
    border: 1px solid #c2c2c2;
    color: #c2c2c2;
    background-color: white;
}

#delete-btn {
    outline: none;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid #ff0000;
    color: #ff0000;
    background-color: white;
    transition: all ease-in-out 0.2s;
    font-size: 14px;
}

#delete-btn:hover {
    background-color: #ffe6e6;
}

#reset-btn {
    outline: none;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid #c2c2c2;
    color: white;
    background-color: #c2c2c2;
    transition: all ease-in-out 0.2s;
    font-size: 13px;
}

#reset-btn:hover {
    border: 1px solid #c2c2c2;
    color: #c2c2c2;
    background-color: white;
}

#reset-btn-disabled {
    outline: none;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid #EBEBEB;
    color: white;
    background-color: #EBEBEB;
    transition: all ease-in-out 0.2s;
    font-size: 13px;
}


#reset-password-btn {
    outline: none;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid red;
    color: red;
    background-color: white;
    transition: all ease-in-out 0.2s;
    font-size: 13px;
}

#reset-password-btn:hover {
    background-color: red;
    color: white;
}

#long-save-btn-disabled {
    width: 100%;
    outline: none;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #c2c2c2;
    color: #c2c2c2;
    background-color: white;
    transition: all ease-in-out 0.2s;
    font-size: 13px;
}

#long-save-btn {
    width: 100%;
    outline: none;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #fc7242;
    color: #fc7242;
    background-color: white;
    transition: all ease-in-out 0.2s;
    font-size: 13px;
}

#long-save-btn:hover {
    background-color: #FC7242;
    color: white;
}

#product-add-btn {
    width: 100%;
    outline: none;
    padding: 3px;
    border-radius: 5px;
    border: 1px solid #fc7242;
    color: white;
    background-color: #fc7242;
    transition: all ease-in-out 0.2s;
    font-size: 11px;
}

#category-add-btn {
    width: 100%;
    padding: 5px 10px;
    outline: none;
    border: 1px solid #fc7242;
    color: #fc7242;
    background: none;
    font-size: 14px;
    transition: all ease-in-out 0.2s;
    border-radius: 10px;
}

#category-edit-btn {
    width: 100%;
    padding: 5px 10px;
    outline: none;
    border: 1px solid #757575;
    color: #757575;
    background: none;
    font-size: 14px;
    transition: all ease-in-out 0.2s;
    border-radius: 10px;
}

#product-add-btn:hover {
    border: 1px solid #fc7242;
    color: #fc7242;
    background-color: white;
}

#category-add-btn:hover {
    background-color: #fcf9ed;
}

#category-edit-btn:hover {
    background-color: #e1e1e1;
}

.leftSlidingPane {
    backgroundColor: rgba(0, 0, 0, 0.5)
}

.scroll-hidden-order-detail {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scroll-hidden-order-detail::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}

/* animation */
.fade-enter {
    opacity: 0;
    position: absolute;
    width: 100%;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}
.fade-exit {
    opacity: 1;
    position: absolute;
    width: 100%;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}