.companyListButton {
    width: 150px;
    margin-right: 5px;
    border-radius: 10px;
    outline: none;
    color: #c2c2c2;
    border: 1px solid #c2c2c2;
    transition: all ease-in-out 0.2s;
    background-color: white;
}

.companyListButton-selected {
    width: 150px;
    margin-right: 5px;
    border-radius: 10px;
    outline: none;
    color: white;
    border: 1px solid #fc7242;
    transition: all ease-in-out 0.2s;
    background-color: #fc7242;
}

.companyListButton:focus {
    outline: none;
}

.GroupButton {
    width: 100%;
    background-color: white;
    border: 1px solid #757575;
    border-radius: 10px;
    color: #757575;
    outline: none;
    transition: all ease-in-out 0.2s;
}

.GroupButton:focus {
    outline: none;
}

.roleSpec-title {
    font-size: 14px;
    color: #757575;
}

.roleSpec-input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #757575;
    padding-left: 10px;
    color: #757575;
    font-size: 14px;
}

.roleSpec-textarea {
    width: 100%;
    height: 150px;
    resize: none;
    border-radius: 5px;
    border: 1px solid #757575;
    padding-left: 10px;
    color: #757575;
    font-size: 14px;
}

.roleSpec-textarea:focus {
    outline: none;
}

.roleSpec-checkBoxTitle {
    font-size: 14px;
    color: #757575;
    font-weight: bold;
    margin-left: 15px;
}

.roleSpec-checkBoxTitle-disabled {
    font-size: 14px;
    color: #c2c2c2;
    font-weight: bold;
    margin-left: 15px;
    text-decoration: line-through;
}

#long-delete-btn-disabled {
    width: 100%;
    outline: none;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #c2c2c2;
    color: #c2c2c2;
    background-color: white;
    transition: all ease-in-out 0.2s;
    font-size: 13px;
}

#long-delete-btn {
    width: 100%;
    outline: none;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #fc7242;
    color: red;
    background-color: white;
    transition: all ease-in-out 0.2s;
    font-size: 13px;
}

.groupAccordionHeaderBtn {
    width: 100%;
    padding: 0px;
    background-color: white;
    border: none;
    text-align: left;
    color: #757575;
    transition: all ease-in-out 0.2s;
}

.groupAccordionHeaderBtn:focus {
    outline: none;
}

.groupAccordionHeaderBtn-selected {
    width: 100%;
    padding: 0px;
    background-color: white;
    border: none;
    text-align: left;
    color: #757575;
    transition: all ease-in-out 0.2s;
}

.groupAccordionHeaderBtn-selected:focus {
    outline: none;
}

.companyListButton {
    margin-bottom: 5px;
    border-radius: 10px;
    outline: none;
    color: #c2c2c2;
    border: 1px solid #c2c2c2;
    transition: all ease-in-out 0.2s;
    background-color: white;
}

.companyListButton-selected {
    margin-bottom: 5px;
    border-radius: 10px;
    outline: none;
    color: white;
    border: 1px solid #fc7242;
    transition: all ease-in-out 0.2s;
    background-color: #fc7242;
}

.companyListButton:focus {
    outline: none;
}

.companyListButton-selected:focus {
    outline: none;
}