.icon {
    width: 100px;
    height: 100px;
    pointer-events: none;
}

.file {
    display: none;
}

.file::file-selector-button {
    font-size: 14px;
    background: #fff;
    border: 1px solid #111;
    border-radius: 12px;
    padding: 4px 32px;
    cursor: pointer;
}

.preview {
    width: 400px;
    height: 200px;
    margin: auto;
    background-color: #fff;
    border-radius: 5px;
    border: 2px dashed #eee;
    padding: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
}

.preview:hover {
    background-color: #efeef3;
    border-color: #757575;
}

.preview.active {
    background-color: #efeef3;
    border-color: #757575;
}

.preview_msg {
    font-size: 14px;
    margin: 5px 0 5px;
    color: #757575;
}

.preview_desc {
    font-weight: 400;
    margin: 0;
    font-size: 14px;
    color: #c2c2c2;
    text-align: center;
}

.file_logo {
    color: #c2c2c2;
}

.file_logo:active {
    color: #757575;
}

.file_name {
    font-weight: 400;
    margin: 5px 0px;
    font-size: 14px;
    color: #757575;
    text-align: center;
}

.message-json {
    font-size: 14px;
    transition: all ease-in-out 0.2s;
}