.App {
  text-align: center;
}

a:hover {
  text-decoration: none;
}

/* always-on scroll bar */
div::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

div::-webkit-scrollbar-thumb {
  background: #c2c2c2;
  border-radius: 5px;
}

div::-webkit-scrollbar-thumb:hover {
  background: #b8b8b8;
}