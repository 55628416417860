.modal-confirmBtn {
    width: 100%;
    border: 1px solid #1e8cfb;
    color:#1e8cfb;
    background-color: white;
    border-radius: 10px;
    transition: all ease-in-out 0.2s;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    height: 30px;
}

.modal-confirmBtn:hover {
    background-color: #e1f5fe;
}

.modal-confirmBtn:focus {
    outline: none;
}

.modal-confirmBtn-disabled {
    width: 100%;
    border: 1px solid #c2c2c2;
    color:#c2c2c2;
    background-color: white;
    border-radius: 10px;
    transition: all ease-in-out 0.2s;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    height: 30px;
}

.modal-confirmBtn-disabled:focus {
    outline: none;
}

.modal-rejectBtn {
    width: 100%;
    border: 1px solid #f44336;
    color: #f44336;
    background-color: white;
    border-radius: 10px;
    transition: all ease-in-out 0.2s;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    height: 30px;
}

.modal-rejectBtn:hover {
    background-color: #ffe6e6;
}

.modal-rejectBtn:focus {
    outline: none;
}