.editProductBtn {
    outline: none;
    font-size: 14px;
    border: 1px solid #757575;
    border-radius: 10px;
    background-color: white;
    color: #757575;
    padding: 3px 10px;
    transition: all ease-in-out 0.2s;
}

.editProductBtn:hover {
    background-color: #ebebeb;
}

.editProductBtn:focus {
    outline: none;
}

.editProductBtn-disabled {
    outline: none;
    font-size: 14px;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    background-color: white;
    padding: 3px 10px;
    color: #ebebeb;
}

.editProductBtn-disabled:focus {
    outline: none;
}

.smallAddBtn {
    padding: 0px;
    background: none;
    border: none;
    color: #fc7242;
    font-size: 13px;
    text-decoration: underline;
}

.smallAddBtn:focus {
    outline: none;
}

.smallAddBtn-disabled {
    padding: 0px;
    background: none;
    border: none;
    color: #ebebeb;
    font-size: 13px;
    text-decoration: underline;
}

.smallAddBtn-disabled:focus {
    outline: none;
}

.amountInput {
    width: 100%;
    height: 100%;
    outline: none;
    padding: 5px;
    border: 1px solid #757575;
    border-radius: 5px;
}

.cancelPaymentBtn {
    outline: none;
    font-size: 14px;
    border: 1px solid red;
    border-radius: 10px;
    background-color: white;
    color: red;
    padding: 3px 10px;
    transition: all ease-in-out 0.2s;
}

.cancelPaymentBtn:hover {
    background-color: #ffcccb;
}

.cancelPaymentBtn:focus {
    outline: none;
}

.cancelPaymentBtn-disabled {
    outline: none;
    font-size: 14px;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    background-color: white;
    padding: 3px 10px;
    color: #ebebeb;
}

.cancelPaymentBtn-disabled:focus {
    outline: none;
}

.topDeleteBtn {
    color: red;
    border: 1px solid red;
    background-color: white;
    padding: 3px 10px;
    font-size: 14px;
    border-radius: 10px;
    transition: all ease-in-out 0.2s;
}

.topDeleteBtn:hover {
    background-color: #ffcccb;
}

.topDeleteBtn:focus {
    outline: none;
}

.topDeleteBtn-disabled {
    padding: 0px;
    border: none;
    background: none;
    color: #ebebeb;
    text-decoration: underline;
    font-size: 13px;
}

.topDeleteBtn-disabled:focus {
    outline: none;
}

.topBtn {
    color: #fc7242;
    border: 1px solid #fc7242;
    background-color: white;
    padding: 3px 10px;
    font-size: 14px;
    border-radius: 10px;
    transition: all ease-in-out 0.2s;
}

.topBtn:hover {
    background-color: #fcf9ed;
}

.topBtn:focus {
    outline: none;
}

.topBtn-disabled {
    padding: 0px;
    border: none;
    background: none;
    color: #ebebeb;
    text-decoration: underline;
    font-size: 13px;
}

.topBtn-disabled:focus {
    outline: none;
}

.product-thead {
    font-size: 14px;
    padding: 10px;
}