.sideNavMenus {
    text-align: left;
}

#side-nav-title {
    font-size: 13px;
}

ul {
    list-style-type: none;
    padding: 0px 5px;
}

li {
    padding: 0;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 5px;
    border-radius: 10px;
}

#side-bar-item-icon {
    margin: 0;
    padding: 0;
    margin-right: 20px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}