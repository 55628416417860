.signup-profileImg-label {
    margin: 5px 0 20px 0;
    font-weight: bold;
    font-size: 13px;
    color: #fc7242;
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
}

input[type="file"] {
    display: none;
}