#topMenu-alert-icon {
    transition: all 0.2s ease-in-out;
    fill: #696969;
}

#topMenu-alert-icon:hover {
    fill: black;
}

#topMenu-arrow-icon {
    transition: all 0.2s ease-in-out;
    fill: #d3d6e4;
}

#topMenu-arrow-icon:hover {
    fill: #696969;
}