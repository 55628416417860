
.withScrollDiv::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 10px;
}

.withScrollDiv::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.withScrollDiv::-webkit-scrollbar-thumb {
    background: #c2c2c2;
    border-radius: 5px;
}

.withScrollDiv::-webkit-scrollbar-thumb:hover {
    background: #b8b8b8;
}